import React from 'react';
import BoxBusca from '../BoxBusca';
import { BannerCapaContainer, BoxTituloBannerCapa } from './BannerCape-styled';

const BannerCapa = ({ filtros }) => {
    return (
        <BannerCapaContainer>
            <BoxTituloBannerCapa>
                <h1>quer comprar ou alugar?</h1>
                <h2>No Classi é o lugar</h2>
            </BoxTituloBannerCapa>

            <BoxBusca filtros={filtros} />
        </BannerCapaContainer>
    );
};

export default BannerCapa;
