import styled from 'styled-components';

export const BoxBuscaContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 487px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.21);
    width: 50%;
    background-color: #fff;
    

    padding: 10px 2%;

    @media (max-width: 950px) {
        width: 100%;
        padding: 20px;
    }
`;

export const BoxInput = styled.div`
    margin: 10px 0;
`;

export const BoxComprarAlugar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16px;

    button {
        width: 148px;
        height: 40px;

        margin: 0;
    }

    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;

        button {
            width: 49%;
            height: 50px;
        }
    }
`;

export const BoxButtonBuscar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 224px;

        margin: 0;
        margin-top: 16px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
`;
