import useHttp from './useHttp';

const baseUrl = process.env.API_URL;

const OpenApi = () => {
    const { get, post } = useHttp(baseUrl);

    const consultarAnuncio = (id) => get(`/vitrine-imoveis/${id}`);

    const consultarAnunciosSimilares = (id) =>
        get(
            `/vitrine-imoveis/consultarAnunciosSimilares?idAnuncio=${id}&qtdAnuncios=8`
        );

    const consultarMotivosDenuncia = () =>
        get(`/vitrine-imoveis/findMotivosDenuncia`);

    const getSitemap = ({ page }) => get(`/site-maps?tipo-anuncio=IMOVEL${page ? '&page=' + page : ''}`);
    const getLancamento = () => get('/vitrine-imoveis/findLancamentosImoveis');

    const getCategorias = () => get('/vitrine-imoveis/consultarCategorias');

    const consultaVitrineImoveis = (body) =>
        post('/vitrine-imoveis/consultaVitrineImoveis', body);

    const filtro = (body) => post('/vitrine-imoveis/filtro', body);

    const localizacao = ({ search, secao }) =>
        get(`/vitrine-imoveis/localizacao?search=${search}&secao=${secao || "IMOVEL_VENDA"}`);

    return {
        consultarAnuncio,
        consultarAnunciosSimilares,
        consultarMotivosDenuncia,
        getSitemap,
        getLancamento,
        getCategorias,
        consultaVitrineImoveis,
        filtro,
        localizacao,
    };
};

export default OpenApi;
