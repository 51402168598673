import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: ${(props) => props.height};
    padding: 15px 0;

    @media (max-width: 425px) {
        height: 450px;
        justify-content: center;
    }

    ${(props) =>
        props.qtdCards === 1 &&
        css`
            @media (max-width: 425px) {
                height: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        `}

    .swiper {
        width: 100%;
    }

    .swiper-slide {
        /* Center slide text vertically */
        display: flex;
        justify-content: space-between;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 20px;
        height: auto;
        cursor: pointer;
        color: rgba(255, 255, 255, 1);
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
        font-size: 18px;
    }
`;

export const Card = styled.div`
    width: 290px;
    height: 377px;
    background: #ffffff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.29794);
    margin-right: 10px;

    @media (max-width: 425px) {
        height: 380px;
    }

    ${(props) =>
        props.qtdCards === 2 &&
        css`
            width: 496px;
            height: 530px;

            @media (max-width: 425px) {
                width: 290px;
                height: 380px;
            }
        `}

    ${(props) =>
        props.qtdCards == 1 &&
        css`
            width: 100%;
            display: flex;
            flex-direction: row;

            @media (max-width: 425px) {
                width: 290px;
                height: 380px;
                display: flex;
                flex-direction: column;
            }
        `}
`;

export const ImageContainer = styled.div`
    height: 50%;

    //obrigatorio para funcionar a imagem utilizando next
    position: relative;
    display: block;

    ${(props) =>
        props.qtdCards === 1 &&
        css`
            width: 55%;
            height: auto;

            @media (max-width: 425px) {
                width: 100%;
                height: 50%;
            }
        `}
`;

export const FavoritoWrapper = styled.div`
    margin-top: 15px;
    margin-right: 22px;
    position: absolute;
    right: 0;
`;

export const Link = styled.a`
    ${(props) =>
        props.qtdCards === 1 &&
        css`
            width: calc(100% - 55%);

            @media (max-width: 425px) {
                width: 100%;
                height: calc(100% - 50%);
            }
        `}
`;

export const TextContainer = styled.div`
    height: 50%;
    padding: 17px 25px;

    display: flex;
    flex-direction: column;

    ${(props) =>
        props.qtdCards === 1 &&
        css`
            height: 100%;
            width: 100%;
        `}
`;

export const Categoria = styled.span`
    font-size: 14px;
    color: #626262;
    margin-bottom: 10px;
`;

export const Titulo = styled.h3`
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: #4a4a4a;

    margin-bottom: 13px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    ${(props) =>
        props.qtdCards === 1 &&
        css`
            font-size: 31px;
            line-height: 36px;

            @media (max-width: 425px) {
                font-size: 18px;
            }
        `}
`;

export const Descricao = styled.span`
    font-size: 14px;
    color: #626262;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const DetalhesImovelWrapper = styled.div`
    width: 100%;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '15px')};

    @media (max-width: 425px) {
        display: none;
    }
`;

export const Preco = styled.span`
    font-size: 16px;
    font-weight: bold;

    text-transform: uppercase;
    color: #288394;

    align-self: flex-end;
    margin-top: auto;
`;

export const InfoContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
`;

export const Label = styled.div`
    border: 1px solid #30bcd5;
    background-color: #30bcd5;
    color: #fff;
    margin-left: 20px;
    margin-top: 10px;
    padding: 8px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
`;

export const Destaque = styled.div`
    border: 1px solid;
    color: #30bcd5;
    background-color: #fff;
    margin-left: 20px;
    margin-top: 10px;
    padding: 8px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
`;

export const SuperDestaque = styled.div`
    border: 1px solid #30bcd5;
    background-color: #30bcd5;
    color: #fff;
    margin-left: 20px;
    margin-top: 10px;
    padding: 8px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
`;
