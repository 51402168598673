import React from 'react';
import { BannerContainer } from './BoxAnuncie-styled.js';
import Link from 'next/link';

const titulo =
    'QUER VENDER OU ALUGAR, IMÓVEL COMERCIAL OU RESIDENCIAL? LOTE OU CHÁCARAS?';
const textBtn = 'ANUNCIE AQUI';
const primaryColor = '#ffa000';
const secondaryColor = '#30bcd5';
const urlBtn = process.env.NEXT_PUBLIC_URL_ANUNCIE;
const urlIsExternal = urlBtn.startsWith('http');

const BannerButton = () => {
    return (
        <BannerContainer backgroundColor={secondaryColor}>
            <BannerContainer.TituloWrapper>
                <BannerContainer.Titulo color={primaryColor}>
                    {titulo}
                </BannerContainer.Titulo>
            </BannerContainer.TituloWrapper>
            <BannerContainer.BotaoWrapper>
                {urlIsExternal ? (
                    <BannerContainer.Botao
                        backgroundcolor={primaryColor}
                        href={urlBtn}
                    >
                        {textBtn}
                    </BannerContainer.Botao>
                ) : (
                    <Link href={urlBtn}>
                        <a>
                            <BannerContainer.Botao
                                backgroundcolor={primaryColor}
                                cli
                            >
                                {textBtn}
                            </BannerContainer.Botao>
                        </a>
                    </Link>
                )}
            </BannerContainer.BotaoWrapper>
        </BannerContainer>
    );
};

export default BannerButton;
