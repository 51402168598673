import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const Destaque = styled.h2`
    color: ${(props) => (props.color ? props.color : '#FFF')};
`;

export const BannerContainer = styled.div`
    width: 100%;
    padding: 20px 16px 28px 17px;
    background-color: ${(props) => props.backgroundColor};
    flex: 1;

    @media screen and (min-width: 480px) {
        padding: 40px 50px 60px;
    }
`;

BannerContainer.TituloWrapper = styled.div`
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
`;

BannerContainer.Titulo = styled.h2`
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 1vw;
    color: #fff;

    destaque {
        color: ${(props) => (props.color ? props.color : '#FFF')};
    }

    @media screen and (min-width: 480px) {
        font-size: 30px;
    }
`;

BannerContainer.BotaoWrapper = styled.div`
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 38px;
`;

BannerContainer.Botao = styled(Button)`
    && {
        width: 287px;
        height: 39px;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: ${(props) => props.backgroundcolor};
        span {
            width: 100%;
            height: 18px;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${(props) => (props.color ? props.color : '#FFF')};
        }

        &:hover {
            background-color: #fff;
            border: solid 1px ${(props) => props.backgroundcolor};
            span {
                color: ${(props) => props.backgroundcolor};
            }
        }
    }
`;
