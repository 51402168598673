/**
 * String Utils packet
 */
export const StringUtils = {
    cleanString: function (value) {
        if (value) {
            return this.normalizeString(value)
                .replace(/\s+/g, '+')
                .replace(/\//g, '+')
                .replace(/-/g, '+')
                .replace(/\./g, '');
        }

        return '';
    },
    normalizeString: function (value) {
        return value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
    },
    getPrimeiroMatch: function (value, regex) {
        return this.getMatch(value, regex, 0);
    },
    getMatch: function (value, regex, index) {
        if (value) {
            let match = value.match(regex);
            if (match && match.length) {
                if (Number.isInteger(index) && index >= 0) {
                    return match[index];
                }

                return match;
            }
        }

        return null;
    },
};
