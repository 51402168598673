import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

import NewCardLancamento from './Cards';
import { Container } from './Cards-styled';

//TODO: tirar swiper com 1 lançamento, arrumar estilos quando houver 2 lançamentos
const MapCards = ({ lancamentos, referrer }) => {
    const isLancamentos = !lancamentos?.[0]?.categoria;

    return (
        <>
            <h2 style={{ color: '#30bcd5', fontSize: '22px', margin: 0 }}>
                {isLancamentos ? 'Lançamento' : 'Imóveis em destaque'}
            </h2>

            {checkReleasesLength(lancamentos)}
        </>
    );

    function checkReleasesLength(lancamentos) {
        if (lancamentos?.length >= 3) {
            return (
                <Container height="440px">
                    <Swiper
                        breakpoints={{
                            320: {
                                slidesPerView: 1.2,
                                spaceBetween: 1,
                                slidesPerGroup: 1,
                                loopFillGroupWithBlank: false,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 3,
                                slidesPerGroup: 2,
                                loopFillGroupWithBlank: false,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 4,
                                slidesPerGroup: 3,
                                loopFillGroupWithBlank: true,
                            },
                        }}
                        pagination={true}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {lancamentos.slice(0, 3).map((item, index) => (
                            <SwiperSlide key={index}>
                                <NewCardLancamento
                                    {...item}
                                    isLancamentos={isLancamentos}
                                    qtdCards={lancamentos.length}
                                    referrer={referrer}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Container>
            );
        }

        if (lancamentos?.length === 2) {
            return (
                <Container height="640px">
                    <Swiper
                        pagination={true}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.1,
                                spaceBetween: 1,
                                slidesPerGroup: 1,
                                loopFillGroupWithBlank: false,
                            },
                            768: {
                                slidesPerView: 1.1,
                                spaceBetween: 1,
                                slidesPerGroup: 1,
                                loopFillGroupWithBlank: false,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 4,
                                slidesPerGroup: 2,
                                loopFillGroupWithBlank: true,
                            },
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {lancamentos.slice(0, 2).map((item, index) => (
                            <SwiperSlide key={index}>
                                <NewCardLancamento
                                    {...item}
                                    isLancamentos={isLancamentos}
                                    qtdCards={lancamentos.length}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Container>
            );
        }

        if (lancamentos?.length === 1) {
            return (
                <Container>
                    {lancamentos.slice(0, 1).map((item, index) => (
                        <NewCardLancamento
                            {...item}
                            key={index}
                            isLancamentos={isLancamentos}
                            qtdCards={lancamentos.length}
                        />
                    ))}
                </Container>
            );
        }
    }
};

export default MapCards;
