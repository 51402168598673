import { StringUtils } from './StringUtils';
import { omit } from 'lodash';

export const formatLocalizacao = (splitedPathname) => {
    if (splitedPathname?.split('-').length > 0) {
        const [estado, cidade, bairro] = splitedPathname.split('-');

        return {
            estado: estado ? decodeURIComponent(estado).toUpperCase() : null,
            cidade: cidade ? decodeURIComponent(cidade) : null,
            bairro: bairro ? [decodeURIComponent(bairro)] : null,
        };
    } else {
        return null;
    }
};

export const formatGroupList = (list) =>
    list?.reduce((acc, cur) => acc.concat(cur.options), []);

export const formatFilterToNewUrl = (filter) => {
    let newUrl = window.location.origin + '/';

    const queryObj = omit(filter, [
        'anunciante',
        'bairros',
        'categoria',
        'estado',
        'cidade',
        'pagina',
        'paginado',
        'localizacao',
        'qtdLancamentosBusca',
        'secao',
    ]);

    const formatedQuery = Object.keys(queryObj)
        .map((key) => {
            if (key === 'caracteristicas' && filter.caracteristicas) {
                return `caracteristicas=[${
                    filter.caracteristicas
                        ?.map((key, idx) => `${idx > 0 ? ',' : ''}${key.valor}`)
                        .filter(Boolean) || ''
                }]`;
            }

            if (key === 'qtdRegistros' && queryObj.qtdRegistros === 12) {
                return '';
            }

            if (key === 'ordenacao' && queryObj.ordenacao === 'RELEVANCIA') {
                return '';
            }

            return filter[key] ? `${key}=${filter[key]}` : '';
        })
        .filter(Boolean)
        .join('&');

    if (filter.anunciante) {
        newUrl += (
            StringUtils.cleanString(filter.anunciante.descricao) + '/'
        ).replaceAll('+', '');
    }

    if (filter.secao) {
        newUrl += filter.secao === 'IMOVEL_VENDA' ? 'comprar' : 'alugar';
    }

    if (filter.categoria) {
        newUrl += `/${StringUtils.cleanString(
            filter.categoria.descricao
        )}-${StringUtils.cleanString(filter.categoria.agrupamento)}`;
    }

    if (filter.localizacao) {
        const splitedUf = filter?.localizacao?.descricao.split(' - ');
        const uf =
            splitedUf?.length > 1
                ? StringUtils.cleanString(splitedUf[1])
                : splitedUf
                ? StringUtils.cleanString(splitedUf[0])
                : '';
        const cidade =
            splitedUf.length > 1
                ? splitedUf?.[0].split(', ')?.length > 1
                    ? StringUtils.cleanString(splitedUf?.[0].split(', ')?.[1])
                    : StringUtils.cleanString(splitedUf?.[0].split(', ')?.[0])
                : '';
        const ufCidade = uf && cidade ? `${uf}-${cidade}` : uf ? uf : '';
        const bairro =
            filter?.localizacao?.descricao.split(', ').length > 1
                ? StringUtils.cleanString(
                      filter?.localizacao?.descricao.split(', ')[0]
                  )
                : '';

        newUrl +=
            ufCidade && !filter.categoria
                ? '/todos/' + ufCidade
                : ufCidade
                ? '/' + ufCidade
                : '';
        newUrl += bairro ? '/' + bairro : '';
    }

    if (typeof filter.pagina !== 'undefined' && filter.pagina != null) {
        newUrl += `?pagina=${filter.pagina}`;
    } else {
        newUrl += '?pagina=0';
    }

    if (formatedQuery) {
        newUrl += '&' + formatedQuery;
    }

    return newUrl;
};

export const formatLocationGroupList = (list) => {
    const oldArray = list?.reduce(
        (acc, cur) =>
            acc.concat(
                cur.options.map((item) => ({
                    ...item,
                    agrupamento: cur.descricao,
                }))
            ),
        []
    );

    const cityFilter = oldArray.filter(
        (item) => item.agrupamento === 'cidades'
    );

    const districtFilter = oldArray.filter(
        (item) => item.agrupamento === 'bairros'
    );

    const stateFilter = oldArray.filter(
        (item) => item.agrupamento === 'estados'
    );

    const newArray = [];

    return newArray.concat(cityFilter, districtFilter, stateFilter);
};
