import styled from 'styled-components';

export const BannerCapaContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const BoxTituloBannerCapa = styled.div`
    max-width: 40%;
    h1 {
        color: #30bcd5;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 0;
        font-size: 46px;
    }

    h2 {
        color: #288394;
        font-size: 36px;
        font-weight: bold;
    }

    @media (max-width: 768px) {
        max-width: 100%;

        h1 {
            font-size: 32px;
            text-align: center;
        }

        h2 {
            font-size: 18px;
            text-align: center;
        }
    }
`;
