import React, { useEffect } from 'react';

import { formatCurrency } from 'utils/formatCurrency';

import DetalhesImovel from 'components/DetalhesImovel';

import { AdImageNotFoundSVG } from '../../utils/icons';

import ImageWithFallback from 'components/ImageWithFallback';
import Favoritar from 'components/Favoritar';

import {
    Card,
    ImageContainer,
    InfoContainer,
    Link,
    TextContainer,
    Destaque,
    Label,
    SuperDestaque,
    Categoria,
    Titulo,
    Descricao,
    DetalhesImovelWrapper,
    Preco,
    FavoritoWrapper,
} from './Cards-styled';
import { sendAnalytics } from 'components/analytics-gjc/analytics-gjc';
import { analyticsEvent } from 'components/analytics-gjc/analytics-gjc-event-enum';

const Cards = ({ qtdCards, isLancamentos, referrer, ...item }) => {
    useEffect(() => {
        if (!isLancamentos) {
            const analyticsJson = {
                id: item.id,
                referrer
            }
    
            sendAnalytics(analyticsEvent.IMPRESSAO_CARD_ANUNCIO, analyticsJson);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendClickCardAnaylicEvent = () => {
        const analyticsJson = {
            id: item.id,
            referrer
        }

        sendAnalytics(analyticsEvent.CLICK_ANUNCIO, analyticsJson);
    }

    return (
        <>
            <Card qtdCards={qtdCards}>
                <ImageContainer qtdCards={qtdCards}>
                    <>
                        <ImageWithFallback
                            src={item.urlImagem220}
                            alt="Imagem do imóvel"
                            layout="fill"
                            onHasError={() => {}}
                            fallbackSrc={AdImageNotFoundSVG}
                        />

                        <InfoContainer>
                            {/* Quando a Home tiver algum imóvel de lançamento */}
                            {validateReleaseType(item.tipoLancamento)}

                            {/* Quando a Home possuir imóveis em destaque */}
                            {validatePriority(item.prioridade)}

                            {isLancamentos === false && (
                                <FavoritoWrapper>
                                    <Favoritar
                                        idAnuncio={item.id}
                                        favorito={item.favorito}
                                        size={18}
                                    />
                                </FavoritoWrapper>
                            )}
                        </InfoContainer>
                    </>
                </ImageContainer>

                <Link
                    onClick={sendClickCardAnaylicEvent}
                    href={item.urlHotsite}
                    target="_blank"
                    rel="noopener noreferrer"
                    qtdCards={qtdCards}
                >
                    <TextContainer qtdCards={qtdCards}>
                        {item.categoria && (
                            <Categoria>{item.categoria}</Categoria>
                        )}

                        <Titulo qtdCards={qtdCards}>{item.titulo}</Titulo>

                        {item.localizacao ? (
                            <Descricao>{item.localizacao} </Descricao>
                        ) : (
                            <Descricao>
                                {item.descricao
                                    .replace(/<\/?[^>]+(>|$)/g, '')
                                    .replace(/&nbsp;/g, '')}
                            </Descricao>
                        )}

                        {qtdCards === 1 || qtdCards === 2 ? (
                            <DetalhesImovelWrapper
                                marginTop={qtdCards === 1 && '58px'}
                            >
                                <DetalhesImovel
                                    fontSize={14}
                                    tamanhoIcon={30}
                                    isLancamentos={isLancamentos}
                                    areaMax={item.qtdMaxArea}
                                    areaMin={item.qtdMinArea}
                                    quartosMax={item.qtdMaxQuartos}
                                    quartosMin={item.qtdMinQuartos}
                                    suitesMax={item.qtdMaxSuites}
                                    suitesMin={item.qtdMinSuites}
                                    qtdMaxVagas={item.qtdMaxVagas}
                                    qtdeVagas={item.qtdMinVagas}
                                />
                            </DetalhesImovelWrapper>
                        ) : (
                            <></>
                        )}

                        <Preco>
                            {item.valor || item.valorMin
                                ? formatCurrency(item.valor || item.valorMin)
                                : 'a combinar'}
                        </Preco>
                    </TextContainer>
                </Link>
            </Card>
        </>
    );

    function validatePriority(priority) {
        switch (priority) {
            case 'ALTA':
                return <SuperDestaque>Super Destaque</SuperDestaque>;
            case 'MEDIA':
                return <Destaque>Destaque</Destaque>;
            default:
                return <></>;
        }
    }

    function validateReleaseType(type) {
        switch (type) {
            case 'EM_CONSTRUCAO':
                return <Label>Em obras</Label>;

            case 'PRONTO_PARA_MORAR':
                return <Label>Pronto para morar</Label>;

            case 'NA_PLANTA':
                return <Label>Na planta</Label>;
            default:
                return <></>;
        }
    }
};

export default Cards;
